<template>
  <div class="login-forgot">
    <div class="mb-0">
      <h3 class="font-weight-normal text-dark">Forgotten Password?</h3>
      <p class="text-dark">Enter your email to reset your password</p>
    </div>
    <b-form class="form" id="kt_login_signin_form" @submit.stop.prevent="onSubmit">
      <div
          v-if="errors.length > 0"
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error.message }}
        </div>
      </div>
      <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
      >
          <b-form-input
              class="form-control h-auto text-black bg-white-o-5 rounded-pill border-dark py-4 px-8 login-input"
              id="example-input-1"
              name="example-input-1"
              placeholder="Email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-1-live-feedback" class="error-div">
            A valid email is required.
          </b-form-invalid-feedback>
        <div class="mt-3 form-group d-flex flex-wrap justify-content-between align-items-center px-1 opacity-60">
          <div class="checkbox-inline">
          </div>
          <router-link
              style="color: #000000"
              class="font-weight-bold"
              :to="{ name: 'login' }"
          >
            Back To Login
          </router-link>
        </div>
      </b-form-group>
      <div class="form-group text-center mt-5">
        <button ref="kt_login_signin_submit" class="btn btn-pill btn-primary opacity-90 px-15 py-3 m-2">Reset</button>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}

.login-input {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.login-input::placeholder {
  color: #000000;
  opacity: 1; /* Firefox */
}

.login-input::-ms-input-placeholder { /* Edge 12 -18 */
  color: #000000;
}

.error-div {
  color: #ffffff;
  background-color: #F64E60;
  padding: 5px 5px;
  margin-top: 5px;
  border-radius: 9999px;
  text-align: center;
}
</style>

<script>
import {mapState} from "vuex";
import {FORGOT_PASSWORD, CLEAR_AUTH_ERRORS} from "@/core/services/store/auth.module";
import Swal from 'sweetalert2'
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "forgot-password",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: ""
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      // clear existing errors
      this.$store.dispatch(CLEAR_AUTH_ERRORS);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // send login request
      this.$store
          .dispatch(FORGOT_PASSWORD, {email})
          // go to which page after successfully login
          .then((data) => {
            if (data._metadata.outcomeCode === 0) {
              Swal.fire('', data._metadata.message, 'success')
              submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
              this.resetForm();
              submitButton.disabled = false;
            } else {
              submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
              submitButton.disabled = false;
            }
          });
    },
    getLogo() {
      return process.env.BASE_URL + "media/logos/logo.jpg";
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
